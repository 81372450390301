import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw/dist/leaflet.draw';
import { EditControl } from "react-leaflet-draw";
import L from 'leaflet';
import proj4 from 'proj4';
import iconMaker from "./MapTools/iconMaker";
//import FullscreenControl from "react-leaflet-fullscreen";
import { Input, Button, Card, CardBody, CardHeader, CardFooter, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { getCiStatus } from "../../../Store/cistatusesgoto/action";
import { getCiCadastros } from "../../../Store/cicadesgoto/action";
import { getPvCadastros } from "../../../Store/pvcadesgoto/action";
import { getPvStatus } from "../../../Store/pvstatusesgoto/action";
import { getRedeStatus } from "../../../Store/redestatusesgoto/action";
import { getRedeCadastros } from "../../../Store/redecadesgoto/action";

import CamadaCiCadastro from "./CamadasEsgoto/CamCiCad"
import CamadaPvCadastro from "./CamadasEsgoto/CamPvCad"
import CamadaRedeCadastro from "./CamadasEsgoto/CamRedeCad0"
import CamadaRedeStatus from "./CamadasEsgoto/CamRedeStatus"
import CamadasWMS from './GeoServer/CamadaWMSGeoServer';
import MakerPopup from './MapTools/MakerPopup';

import NewCadModal from "./Cadastrar/AddNewCad"
import { formMaker } from "../Helpers/Tools/makerform"
import MinimapControl from "./MapTools/MiniMapa"

const { BaseLayer, Overlay } = LayersControl;

function useDataFetch(actionFunction, nameFunction, dataProperty) {
    const dispatch = useDispatch();
    const data = useSelector(state => state[nameFunction][dataProperty]);
    useEffect(() => {
        if (!data.length) {
            dispatch(actionFunction());
        }
    }, [dispatch, data]);
    return data;
};

function Esgoto() {
    const [map, setMap] = useState(null);
    const [startposition, setStartPosition] = useState([-25.44057394677725, -49.244483991682344 ]); // [-27.613290, -48.594782]); posição inicial no mapa
    const [newmodalMakerIsOpen, setModalMakerIsOpen] = useState("");

    const ciCadastros = useDataFetch(getCiCadastros, 'CiCadastros', 'ciCadastros');
    const ciStatus = useDataFetch(getCiStatus, 'CiStatus', 'ciStatus');
    
    const pvCadastros = useDataFetch(getPvCadastros, 'PvCadastros', 'pvCadastros');
    const pvStatus = useDataFetch(getPvStatus, 'PvStatus', 'pvStatus');
    
    const redeCadastros = useDataFetch(getRedeCadastros, 'RedeCadastros', 'redeCadastros');
    const redeStatus = useDataFetch(getRedeStatus, 'RedeStatus', 'redeStatus');

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchMarker, setSearchMarker] = useState(null);
    const searchLayer = L.layerGroup(); // or L.featureGroup(), depending on your use case
    const featureGroupRef = useRef();
    const { successMessage, errorMessage } = useSelector((state) => ({
        successMessage: state.CiCadastros.successMessage,
        errorMessage: state.CiCadastros.errorMessage,
    }));

    const [popupCoords, setPopupCoords] = useState(null);
    const newTool = (e) => {
        const latlng = e.layer._latlng;
        setPopupCoords(latlng);
        //console.log(popupCoords);
        // Verifique se o tipo de criação é um marcador
        if (e.layerType === 'marker') {
            const marker = e.layer;
            // Adicione um popup ao marcador
            marker.bindPopup(formMaker).openPopup();
        }
    };

    const mapSearchlocal = (local, name) => {
        // Remover o marcador existente
        if (searchMarker) {
            map.removeLayer(searchMarker);
        }
        map.flyTo(
            local,
            21,
            {
                animate: true,
                duration: 1,
                easeLinearity: 0.5,
                noMoveStart: true
            }
        );
        setSearchMarker(L.marker(local, { icon: iconMaker })
            .bindPopup(`
            <style>
                .leaflet-popup-content{
                    width: 100px !important;
                }
            </style>
            <div class="divpopupmarker row gy-5 ">
                <form>
                    <div class="divtablemarker col-12 g-xxl-12 mt-2">
                        <label class="form-label mt-0 fs-12 mb-0" for="id_ci">
                            ID: ${name}
                        </label>
                    </div>
                </form>
            </div>`
            ).addTo(map)
            //.openPopup()
        );
        setSearchResults([]);
    };

    const mapGetCenter = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                let { latitude, longitude } = position.coords;
                map.flyTo([latitude, longitude], map.getZoom());
                L.marker(
                    [latitude, longitude],
                    { icon: iconMaker }
                ).bindPopup(formMaker).addTo(map).openPopup();
            },
            (error) => {
                console.error("Error getting location:", error.message);
            })
    };

    const filterDataAsync = async (data) => {
        const searchLower = searchKeyword.toLowerCase();
        return data.filter(item =>
            Object.keys(item).some(key =>
                typeof item[key] === 'string' && item[key].toLowerCase().includes(searchLower)
            )
        );
    };

    const handleSearch = async () => {
        try {
            const [resultsCiCad, resultsCiStt, resultsPvCad, resultsPvStt, resultsRedeCad, resultsRedeStt] =
                await Promise.all([filterDataAsync(ciCadastros), filterDataAsync(ciStatus), filterDataAsync(pvCadastros),
                filterDataAsync(pvStatus), filterDataAsync(redeCadastros), filterDataAsync(redeStatus)]);
            const combinedResults = [...resultsCiCad, ...resultsCiStt, ...resultsPvCad,
            ...resultsPvStt, ...resultsRedeCad, ...resultsRedeStt];
            setSearchResults(combinedResults);

            if (searchMarker) {
                map.removeLayer(searchMarker);
                setSearchMarker(null);
            }
        } catch (error) {
            console.error('Erro durante a filtragem:', error);
        }
    };

    const handleLocationClick = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setStartPosition([latitude, longitude]);
                    setPopupCoords({ lat: latitude, lng: longitude });
                    console.log(popupCoords);

                },
                (error) => {
                    console.error("Error getting location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const transformCoord = (coord) => {
        try {
            let matchesLat, matchesLong;
            const sourceEPSG = coord.split(';')[0].split('=')[1]
            proj4.defs("EPSG:" + sourceEPSG, "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
            if (coord.includes("MULTILINESTRING")) {
                const matches = coord.match(/MULTILINESTRING \(\(([^ ]+) ([^)]+), ([^)]+) ([^)]+)\)\)/);
                matchesLat = (parseFloat(matches[1]) + parseFloat(matches[3])) / 2;
                matchesLong = (parseFloat(matches[2]) + parseFloat(matches[4])) / 2;
                console.log(matchesLat)
                console.log(matchesLong)
            } else {
                const matches = coord.match(/POINT \(([^ ]+) ([^)]+)\)/);
                matchesLat = parseFloat(matches[1]);
                matchesLong = parseFloat(matches[2]);
            }
            return [matchesLong, matchesLat];
            /*
            const coord_a = [matchesLat, matchesLong];
            const transformed = proj4("EPSG:" + sourceEPSG, "EPSG:4326", coord_a);
            return [transformed[1], transformed[0]];
            */
            }
        catch (err) { console.log(err); return startposition }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <div className="content h-50 w-100">
                            <Card>
                                <CardHeader className="justify-content-between hstack text-nowrap" style={{ position: 'relative' }}>
                                    <Input
                                        type="text"
                                        value={searchKeyword}
                                        onChange={(e) => { setSearchKeyword(e.target.value); handleSearch() }}
                                        onClick={() => handleSearch()}
                                        placeholder="Pesquisar..."
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearch();
                                            }
                                        }}
                                        className='w-50 me-3'
                                    />
                                    {/*<SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "100px" }}></SimpleBar>*/}
                                    {searchKeyword && searchResults && searchResults.length > 0 && (
                                        <div
                                            className='w-50 px-3'
                                            id="searchResults"
                                            style={{
                                                position: 'absolute',
                                                top: '100%',
                                                left: '0',
                                                width: '100%',
                                                maxHeight: '200px',
                                                overflowY: 'auto',
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                zIndex: '99'
                                            }}>
                                            {searchKeyword && searchResults.map(
                                                (item) => (
                                                    <p className="fs-5" onClick={() => mapSearchlocal(transformCoord(item.geom), item.id)} style={{ cursor: 'pointer', color: "#555" }}>
                                                        {Object.keys(item).includes("id_ci") ? item.id_ci : Object.keys(item).includes("id_pv") ? item.id_pv : item.id}
                                                        {" "}-{" "}
                                                        {Object.keys(item).includes("id_ci") ? "CAIXA DE INSPEÇÂO" : Object.keys(item).includes("id_pv") ? "POÇO DE VISITA" : "REDE"}
                                                        {" "}-{" "}
                                                        {item.logradouro}
                                                        {" "}-{" "}
                                                        {item.bairro}
                                                        {/*{Object.keys(item).map((key) => (key !== 'geom' ? item[key] : null)).join(", ")}*/}
                                                    </p>
                                                )
                                            )}
                                        </div>
                                    )}

                                    <Button className="btn-primary mt-0 end-0 shadow-lg p-2 px-4" onClick={handleSearch}>
                                        Pesquisar
                                    </Button>
                                    <Button className="btn-success mt-0 end-0 shadow-lg p-2 px-4 ms-auto" onClick={() => mapGetCenter("")}>
                                        Meu Local
                                    </Button>
                                </CardHeader>

                                <CardBody>
                                    <div id="map" className="map h-100" style={{ position: "relative", overflow: "hidden", zIndex: 98 }}>
                                        <MapContainer center={startposition} zoom={18} style={{ height: '600px' }}
                                            maxZoom={22} keyboard={false} scrollWheelZoom={true} ref={setMap} >
                                            <LayersControl position="topright">
                                                <FeatureGroup ref={featureGroupRef}>

                                                    <BaseLayer name="Webmap Google Maps Street" >
                                                        {/*Opções: h, m, p, r, s, t, y, */}
                                                        <TileLayer
                                                            url="https://{s}.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                                                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                                            minZoom={1}
                                                            maxZoom={22}
                                                        />
                                                    </BaseLayer>

                                                    <BaseLayer name="Webmap Google Maps Satelite" checked>
                                                        <TileLayer
                                                            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                                            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                                            minZoom={1}
                                                            maxZoom={22}
                                                            //tileSize={256} // Ajuste do tamanho dos tiles se necessário
                                                            detectRetina={true} // Usa tiles de alta resolução para monitores Retina
                                                        />
                                                    </BaseLayer>

                                                    <MinimapControl parentMap={map} position="topleft" />

                                                    {popupCoords && (
                                                        <MakerPopup popupCoords={popupCoords} />
                                                    )}

                                                    <CamadaCiCadastro ciCadastros={ciCadastros} ciStatus={ciStatus} />

                                                    <CamadaPvCadastro pvCadastros={pvCadastros} pvStatus={pvStatus}/>

                                                    <CamadaRedeStatus redeStatus={redeStatus}/>

                                                    <CamadaRedeCadastro redeCadastros={redeCadastros} redeStatus={redeStatus}/>

                                                    <EditControl position="bottomright" featureGroup={featureGroupRef.current} onCreated={(e) => { newTool(e) }}
                                                        draw={{
                                                            rectangle: true,
                                                            circle: { shapeOptions: { color: 'red', }, },
                                                            circlemarker: { shapeOptions: { color: 'red', }, },
                                                            marker: { icon: iconMaker },
                                                            polyline: { shapeOptions: { color: 'blue', }, metric: true, feet: true, },
                                                            polygon: {
                                                                allowIntersection: true, drawError: {
                                                                    color: 'red',
                                                                    message: '<strong>Error:<strong>Shape cannot intersect!',
                                                                },
                                                                shapeOptions: { color: 'blue', },
                                                            },
                                                        }} />

                                                    <CamadasWMS />
                                                </FeatureGroup>
                                            </LayersControl>

                                        </MapContainer>
                                    </div>
                                </CardBody>
                                <CardFooter className="d-flex justify-content-center align-items-center">
                                    {successMessage && <div className="success-message">{successMessage}</div>}
                                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                                </CardFooter>
                            </Card >
                        </div >
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Esgoto;